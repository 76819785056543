import React from 'react';
import { Notification } from '@in/component-library';
import useMyCluster from '@hooks/cluster/use-my-cluster';

const LegalOwnerNotification = () => {
  const { cluster } = useMyCluster();
  return (
    <Notification type="info">
      <h3>Sjekk juridisk eier</h3>
      <p>Sjekk at juridisk eier er korrekt før dere sender inn søknaden. Nåværende registrert eier er:</p>
      <p>
        <b>{cluster?.owner?.name}</b>
      </p>
      <p>Dersom dette ikke stemmer må det rettes før dere starter å fylle ut søknaden.</p>
      <p>
        Ta kontakt på <a href="mailto:flerbedriftsportal@innovasjonnorge.no">flerbedriftsportal@innovasjonnorge.no</a>{' '}
        for å endre eier.
      </p>
    </Notification>
  );
};

export default LegalOwnerNotification;
